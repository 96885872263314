import React from "react";
import PropTypes from "prop-types";

const TextBlock = function ({ text }) {

  const html = text.childMarkdownRemark.html;
  
  return (
    <div className="text-block" dangerouslySetInnerHTML={{ __html: html }} />
  );
};

export default TextBlock;

TextBlock.propTypes = {
  text: PropTypes.object
};