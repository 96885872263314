import React from "react";
import PropTypes from "prop-types";

import TextBlock from "../globals/TextBlock";

const ContactComponent = function({ sectionData, contactDetails }) {
  const { email, address, googleMapsLink } = contactDetails;
  const addressHtml = address.childMarkdownRemark.html;
  return (
    <>
      {/* ADDRESS TEXT */}
      {sectionData.addressText && (
        <TextBlock
          text={sectionData.addressText}
          className="address-text section-text"
        />
      )}
      <div
        className="address text-block"
        dangerouslySetInnerHTML={{ __html: addressHtml }}
      />
      <p className="map-link text-block">
        <a href={googleMapsLink} target="_blank" rel="noopener noreferrer">
          View on a map
        </a>
      </p>
      {/* EMAIL TEXT */}
      {sectionData.text && (
        <TextBlock text={sectionData.text} className="section-text" />
      )}
      <div className="contact-section-email">
        <p>
          <a href={`mailto:${email}`}>{email}</a>
        </p>
      </div>
    </>
  );
};

export default ContactComponent;

ContactComponent.propTypes = {
  sectionData: PropTypes.object,
  contactDetails: PropTypes.object
};
