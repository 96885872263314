import React from "react";
import PropTypes from "prop-types";
import SectionLayout from "../layouts/SectionLayout";
// Section components
import TextSectionComponent from "./TextSectionComponent";
import AboutComponent from "./AboutComponent";
import DifficultiesComponent from "./DifficultiesComponent";
import ContactComponent from "./ContactComponent";

const PageContent = function ({ sectionData, contactDetails }) {
  const type = sectionData.__typename;
  if (type === 'ContentfulIntroductionSection' || type === 'ContentfulTextSection' ) {
    return (
      <SectionLayout sectionData={sectionData}>
        <TextSectionComponent sectionData={sectionData} contactDetails={contactDetails} />
      </SectionLayout>
    );
  }
  if ( type === 'ContentfulAboutMe' ) {
    return (
      <SectionLayout sectionData={sectionData}>
        <AboutComponent sectionData={sectionData} contactDetails={contactDetails} />
      </SectionLayout>
      );
  }
  if ( type === 'ContentfulDifficulties' ) {
    return (
      <SectionLayout sectionData={sectionData}>
        <DifficultiesComponent sectionData={sectionData} contactDetails={contactDetails} />
      </SectionLayout>
    );
  }
  if ( type === 'ContentfulContactSection' ) {
    return (
      <SectionLayout sectionData={sectionData}>
        <ContactComponent sectionData={sectionData} contactDetails={contactDetails} />
      </SectionLayout>
    );
  }
};

export default PageContent;

PageContent.propTypes = {
  sectionData: PropTypes.object,
  contactDetails: PropTypes.object
};

