import React from "react";
import PropTypes from "prop-types";

const SectionLayout = function({ sectionData, children }) {

  const slug = sectionData.slug;
  const sectionTitle = sectionData.sectionHeader
    ? sectionData.sectionHeader
    : sectionData.title;

  return (
    <section id={slug} className="page-section">
      <h2 className="section-title">{ sectionTitle }</h2>
      { children }
    </section>
  );
};

export default SectionLayout;

SectionLayout.propTypes = {
  children: PropTypes.node.isRequired,
  sectionData: PropTypes.object
};