import React from "react";
import PropTypes from "prop-types";

import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Header = function ({ title, header, subHeader, qualifications, portrait, contactDetails }) {
  return (
    <header className="site-header">
      <div className="site-header-inner">
        <figure className="header-portrait">
          <GatsbyImage
            image={getImage(portrait)}
            alt={portrait.title}
            critical
            fadeIn={false}
          />
        </figure>
        <h1 className="site-header-title">
          <span className="header">{ header } </span><br/>
          <span className="qualifications">{ qualifications } </span>
          <span className="sub-header">{ subHeader }</span>
        </h1>
        <div className="header-contact-details">
          <p className="header-contact-detail">
            <span className="icon-mail icon"></span>
            <a href={`mailto:${contactDetails.email}`}>
              {contactDetails.email}
            </a>
          </p>
        </div>
      </div>
    </header>
  );
};

export default Header;

Header.propTypes = {
  title: PropTypes.string,
  header: PropTypes.string,
  subHeader: PropTypes.string,
  qualifications: PropTypes.string,
  portrait: PropTypes.object,
  contactDetails: PropTypes.object
};