import React, { useState } from "react";
import PropTypes from "prop-types";

import Link from "gatsby-link";

import withSizes from "react-sizes";

const noop = function () {};

const SiteNavigation = function ({ sections, mobileNavSize }, location) {
  return (
    <>
      {mobileNavSize ?
        <MobileNav sections={sections} />
      :
        <DesktopNav sections={sections} />
      }
    </>
  );
};

function DesktopNav ({sections}) {
  return (
    <div className="site-nav-wrapper">
      <nav className="site-nav" role="navigation">
        <NavLinks sections={sections} />
      </nav>
    </div>
  );
}

function MobileNav({sections}) {

  const [navOpen, toggleNav] = useState(false);
  
  let navClass = ["site-nav", "site-nav-mobile"];
  if (navOpen) {
    navClass.push("menu-on");
  }

  return (
    <>
      <HamburgerButton navOpen={navOpen} toggleNav={toggleNav} />
      <div className="site-nav-wrapper site-nav-wrapper-mobile">
        <nav className={navClass.join(" ")} role="navigation">
          <NavLinks sections={sections} toggleNav={toggleNav} />
        </nav>
      </div>
    </>
  );
}

function NavLinks({ sections, toggleNav}) {
  return (
    <>
      {sections.map(sectionData => (
        !sectionData.hideFromNavigation && (
          <Link
            className="site-nav-link"
            key={sectionData.id}
            to={`/#${sectionData.slug}`}
            onClick={() => { toggleNav(false); }}
          >
            {sectionData.title}
          </Link>
        )
      ))}
    </>
  );
}

function HamburgerButton ({navOpen, toggleNav}) {

  let buttonClass = ["hamburger-button"];
  if ( navOpen ) {
    buttonClass.push("menu-on");
  }

  return (
    <a role="button" className={buttonClass.join(" ")} onClick={() => { toggleNav(!navOpen); }}>
      <div className="hamburger-button-inner">
        <div className="hamburger-line top" />
        <div className="hamburger-line middle" />
        <div className="hamburger-line bottom" />
      </div>
    </a>
  );
}

// DEFINE Size for mobile nav
const mapSizesToProps = ({ width }) => ({
  mobileNavSize: width <= 960
});

export default withSizes(mapSizesToProps)(SiteNavigation);

SiteNavigation.propTypes = {
  sections: PropTypes.array,
  mobileNavSize: PropTypes.bool
};

DesktopNav.propTypes = {
  sections: PropTypes.array
};

MobileNav.propTypes = {
  sections: PropTypes.array
};


NavLinks.defaultProps = {
  sections: [],
  toggleNav: noop,
};

NavLinks.propTypes = {
  sections: PropTypes.array,
  toggleNav: PropTypes.func,
};

HamburgerButton.propTypes = {
  navOpen: PropTypes.bool,
  toggleNav: PropTypes.func,
};