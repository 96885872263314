import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'

import SEO from '../globals/seo'
import Header from '../components/Header'
import SiteNavigation from '../components/SiteNavigation'
import PageContent from '../components/PageContent'
import Logos from '../components/logos'

// FRAGMENTS
import ContactDetailsFragment from '../fragments/contact-details-fragment'
import IntroductionFragment from '../fragments/section-introduction-fragment'
import AboutFragment from '../fragments/section-about-fragment'
import DifficultiesFragment from '../fragments/section-difficulties-fragment'
import ContactFragment from '../fragments/section-contact-fragment'
import TextSectionFragment from '../fragments/section-text-fragment'

class HomePage extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		const data = this.props.data.allContentfulTheWebsite.edges[0].node
		const contactDetails = data.contactDetails
		const sections = data.siteSections

		return (
			<>
				<SEO />
				<div className='wrapper'>
					<SiteNavigation sections={sections} />
					<article className='site-content'>
						<Header
							title={data.siteTitle}
							header={data.siteHeader}
							subHeader={data.siteSubHeader}
							introText={data.introductionText}
							qualifications={data.qualifications}
							portrait={data.portrait}
							contactDetails={contactDetails}
						/>
						{sections.map((sectionData) => (
							<PageContent
								key={sectionData.id}
								sectionData={sectionData}
								contactDetails={contactDetails}
							/>
						))}
						<Logos logos={data.logos} />
					</article>
				</div>
			</>
		)
	}
}

export default HomePage

export const HomeQuery = graphql`
	query HomeQuery {
		allContentfulTheWebsite(limit: 1) {
			edges {
				node {
					siteTitle
					siteHeader
					siteSubHeader
					qualifications
					portrait {
						title
						gatsbyImageData(layout: CONSTRAINED, width: 520)
					}
					logos {
						id
						title
						description
						gatsbyImageData(layout: CONSTRAINED, width: 500)
					}
					...ContactDetailsFragment
					siteSections {
						__typename
						... on ContentfulIntroductionSection {
							...IntroductionFragment
						}
						... on ContentfulAboutMe {
							...AboutFragment
						}
						... on ContentfulDifficulties {
							...DifficultiesFragment
						}
						... on ContentfulContactSection {
							...ContactFragment
						}
						... on ContentfulTextSection {
							...TextSectionFragment
						}
					}
				}
			}
		}
	}
`

HomePage.propTypes = {
	data: PropTypes.object,
}
