import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Logos = function ({ logos }) {
  return (
    <div className="logos">
    {logos.map(logo => (
      <figure className="logo" key={logo.id}>
        <a href={logo.description} target='_blank' rel='noopener noreferrer'>
          <GatsbyImage image={getImage(logo)} alt={logo.title} critical />
        </a>
      </figure>
    ))}
    </div>
  );
};

export default Logos;

Logos.propTypes = {
  logos: PropTypes.array
};