import React from "react";
import PropTypes from "prop-types";

import TextBlock from "../globals/TextBlock";

const DifficultiesComponent = function({ sectionData, contactDetails }) {
  return (
    <>
      {/* TEXT */}
      {sectionData.text && (
        <TextBlock text={sectionData.text} className="section-text" />
      )}
      {/* DIFFICULTIES */}
      {sectionData.difficultiesList && (
        <DifficultiesList list={sectionData.difficultiesList} />
      )}
    </>
  );
};

export default DifficultiesComponent;

DifficultiesComponent.propTypes = {
  sectionData: PropTypes.object,
  contactDetails: PropTypes.object
};

DifficultiesList.propTypes = {
  list: PropTypes.array,
};

function DifficultiesList ({ list }) {
  return (
    <div className="difficulties-list">
      {list.map((item, index) => (
        <div
          key={index}
          className="difficulties-list-item"
        >
          {item}  
        </div>
      ))}
    </div>
  );
}