import React from "react";
import PropTypes from "prop-types";

import Image from "gatsby-image";
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import TextBlock from "../globals/TextBlock";

const AboutComponent = function ({ sectionData, contactDetails }) {
  const {image} = sectionData;
  return (
    <>
      {image && (
        <figure className="section-image about-image">
          <GatsbyImage
            image={getImage(image)}
            alt={image.title}
            critical={true}
          />
        </figure>
      )}
      <TextBlock text={sectionData.text} className="section-text" />
    </>
  );
};

export default AboutComponent;

AboutComponent.propTypes = {
  sectionData: PropTypes.object,
  contactDetails: PropTypes.object
};