import React from "react";
import PropTypes from "prop-types";

import TextBlock from "../globals/TextBlock";

const IntroductionComponent = function({ sectionData }) {
  return <TextBlock text={sectionData.text} className="section-text" />;
};

export default IntroductionComponent;

IntroductionComponent.propTypes = {
  sectionData: PropTypes.object
};
