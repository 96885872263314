import React, { Component } from "react";
import Helmet from "react-helmet";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import Facebook from "./facebook";
import Twitter from "./twitter";
import DevTest from "./DevTest";

// FRAGMENTS
import ContactDetailsFragment from "../fragments/contact-details-fragment";

export default class SEO extends Component {

  render() {
    const pageTitle = this.props.title;
    const pageData = this.props.seoData;
    console.log('pageData', pageData)
    return (
      <StaticQuery
        query={SeoQuery}
        render={({ site, allContentfulTheWebsite }) => {
          const siteData = allContentfulTheWebsite.edges[0].node;
          const siteUrl = site.siteMetadata.siteUrl;
          const siteLanguage = site.siteMetadata.siteLanguage;
          const ogLanguage = site.siteMetadata.ogLanguage;
          const siteTitle = siteData.siteTitle;
          const siteDescription = siteData.siteDescription;
          const pageDescription = pageData ? pageData.pageDescription : null;
          let cardImage = pageData
            ? pageData.previewImage
            : siteData.previewImage;
          let cardSize = pageData
            ? pageData.twitterCardSize
            : siteData.twitterCardSize;
          if (cardSize === "Large") {
            cardSize = "summary_large_image";
            cardImage = cardImage.wide.src;
          } else {
            cardSize = "summary";
            cardImage = cardImage.square.src;
          }
          const seo = {
            title: pageTitle
              ? `${pageTitle} | ${siteTitle}`
              : siteTitle,
            description: pageDescription || siteDescription,
            cardSize: cardSize,
            image: cardImage,
            url: siteUrl,
            facebookAuthor: siteData.contactDetails.facebookHandle,
            twitterAuthor: siteData.contactDetails.twitterHandle
          };
          console.log('seo', seo)
          return (
            <>
              <DevTest type="no-robots" />
              <Helmet title={seo.title}>
                <html lang={siteLanguage} />
                <meta name="description" content={seo.description} />
                <meta name="image" content={seo.image} />
              </Helmet>
              <Facebook
                desc={seo.description}
                image={seo.image}
                title={seo.title}
                type="website"
                url={seo.url}
                locale={ogLanguage}
                name={seo.facebookAuthor}
              />
              <Twitter
                title={seo.title}
                image={seo.image}
                type={seo.cardSize}
                desc={seo.description}
                username={seo.twitterHandle}
              />
              {/* <p>Site title: {siteTitle}</p>
              <p>Page title: {pageTitle}</p>
              <p>Full title: {seo.title}</p>
              <p>Site description: {siteDescription}</p>
              <p>Page description: {pageDescription}</p>
              <p>Actual description: {seo.description}</p>
              <p>Card size: {cardSize}</p>
              <p>Card image</p>
              <div>
                <img src={seo.image} />
              </div> */}
            </>
          );
        }}
      />
    );
  }
}

SEO.propTypes = {
  title: PropTypes.string,
  data: PropTypes.object,
  seoData: PropTypes.object
};

export const SeoQuery = graphql`
         query SeoQuery {
           site {
             buildTime(formatString: "YYYY-MM-DD")
             siteMetadata {
               siteUrl
               siteLanguage
               ogLanguage
             }
           }
           allContentfulTheWebsite(limit: 1) {
             edges {
               node {
                 siteTitle
                 siteDescription
                 previewImage {
                   id
                   square: gatsbyImageData(layout: FIXED, width: 512, height: 512)
                   wide: gatsbyImageData(layout: FIXED, width: 1200, height: 675)
                 }
                 ...ContactDetailsFragment
               }
             }
           }
         }
       `;
